<template>
  <div id="app">
    <header id="header">
      <div class="inner">
        <h1 class="logo">
          <a href="#">
            <img src="assets/mainimg/logo.png" alt="SBS아카데미컴퓨터아트학원">
          </a>
        </h1>
      </div>
    </header>
    <main>메인</main>
    <footer>하단</footer>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#header {
  border: 1px solid black;
  background-color: #fff;
  height: 90px;
  width: 100%;
  position: fixed;
  z-index: 1000;
}

main {
  padding-top: 90px;
  background: #f2f4f7;
  min-height: 700px;
}

footer {
  background-color: darkgray;
  height: 310px;
}
body{
  margin: 0px;
}
.inner{
  padding: 0 20px;
  max-width: 1920px;
  height: 90px;
  margin: 0 auto;
}
.logo{
  position: absolute;
  top: 3px;
  left: 40px;
}
</style>
